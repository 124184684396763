<template>
<div class="project-person">
  <div class="top">
    <Button type="primary" @click="batchSet">{{!batchStatus ? $t('project_create_personnel_batch') : '取消批量'}}</Button>
    <div v-if="batchStatus" class="sync-settings">
      <span class="sync-settings-name">同步设置:</span>
      <Checkbox  v-for="(item,index) in syncSettingsList" :key="item.id" v-model="item.status" @on-change="changeProceDure">{{item.name}}</Checkbox>
    </div>

  </div>
  <div class="cont">
<!--    <Table ref="selection" :columns="columns" :data="dataList" @on-selection-change="selectData"></Table>-->
    <table class="cont-table">
      <tr>
        <th v-if="batchStatus"><Checkbox v-model="allSelect" @on-change="selectDataAll"></Checkbox></th>
        <th style="width: 100px">批次</th>
        <th v-for="(item,index) in procedureList" :key="item.id + 'th'">{{item.name}}</th>
        <th>单次任务时间</th>
        <th>单次任务题数</th>
      </tr>
      <tr v-for="item in  dataList" :key="item.id + 'tr'">
        <td v-if="batchStatus"><Checkbox v-model="item.status" @on-change="selectData(item)"></Checkbox></td>
        <td>{{item.name}}</td>
        <td v-for="(sItem,index) in procedureList" :key="sItem.id + 'td'">
          <el-select
              v-model="item['index' + index]"
              multiple
              filterable
              remote
              :remote-method="(query) => remoteMethod(query,item,'userList' + index)"
              :loading="sItem.loading"
              :disabled="batchStatus && item['isEdit' + index]"
              @change="changeSelect(item,'index' + index,'userList' + index)"
          >
            <el-option
                v-for="ssItem in item['userList' + index]"
                :key="ssItem.id"
                :label="ssItem.nickname + '('+ssItem.id+')'"
                :value="ssItem.id">
            </el-option>
          </el-select>
          <div style="height: 40px;display: flex;align-items: center">
            <div v-if="sItem.name.indexOf('审核') != -1" class="recommend-user">
              <i-switch v-model="item['single' + index]"
                        @on-change="changeSelect(item,'single' + index)"
                        :disabled="batchStatus && item['isEdit' + index]"
              />驳回限制
              <Tooltip>
                <Icon type="ios-help-circle-outline" :size="16" />
                <div slot="content" style="white-space: normal">
                  <p>当前数据通过后如果被驳回无法再次向前驳回</p>
                </div>
              </Tooltip>
            </div>
          </div>
          <div style="height: 20px" >
            <p style="cursor: pointer;" v-if="index > 0" @click="changeLock(item,'isLock' + index,item['isEdit' + index])">{{item['isLock' + index] =='0' ? '未锁定' : '锁定'}}</p>
          </div>
        </td>
        <td>
          <el-select v-model="item.receive_expire"
                     popper-class="select-popper"
                     :disabled="batchStatus && item['receive_expire_isEdit']"
                     @change="changeSelect(item,'receive_expire')"
          >
            <el-option
                v-for="sItem in prescriptionList"
                :key="sItem.value"
                :label="sItem.label"
                :value="sItem.value">
            </el-option>
          </el-select>
          <div style="height: 40px;display: flex;align-items: center">
          </div>
          <div style="height: 20px">

          </div>
        </td>
        <td>
          <el-select v-model="item.receive_count"
                     popper-class="select-popper"
                     :disabled="batchStatus && item['receive_count_isEdit']"
                     @change="changeSelect(item,'receive_count')"
          >
            <el-option
                v-for="sItem in questionsNumList"
                :key="sItem.value"
                :label="sItem.label"
                :value="sItem.value">
            </el-option>
          </el-select>
          <div style="height: 40px;display: flex;align-items: center">
          </div>
          <div style="height: 20px">

          </div>
        </td>
      </tr>
    </table>
<!--    <el-table-->
<!--        :data="dataList"-->
<!--        style="width: 100%"-->
<!--        @selection-change="selectData" ref="multipleTable">-->
<!--      <el-table-column-->
<!--          type="selection"-->
<!--          width="55"-->
<!--          v-if="batchStatus"-->
<!--      >-->
<!--      </el-table-column >-->
<!--      <el-table-column-->
<!--          prop="name"-->
<!--          label="批次"-->
<!--          width="180">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          :prop="'index' + index"-->
<!--          :label="item.name" v-for="(item,index) in procedureList" :key="item.id">-->
<!--        <template slot-scope="scope">-->
<!--          <el-select-->
<!--              v-model="scope.row['index' + index]"-->
<!--              multiple-->
<!--              filterable-->
<!--              remote-->
<!--              :remote-method="(query) => remoteMethod(query,scope.row,'userList' + index)"-->
<!--              :loading="item.loading"-->
<!--              :disabled="batchStatus && scope.row['isEdit' + index]"-->
<!--              @change="changeSelect(scope.row,'index' + index,'userList' + index)"-->
<!--          >-->
<!--            <el-option-->
<!--                v-for="sItem in scope.row['userList' + index]"-->
<!--                :key="sItem.id"-->
<!--                :label="sItem.nickname + '('+sItem.id+')'"-->
<!--                :value="sItem.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--          <div style="height: 40px;display: flex;align-items: center">-->
<!--            <div v-if="item.name.indexOf('审核') != -1" class="recommend-user">-->
<!--              <i-switch v-model="scope.row['single' + index]"-->
<!--                        @on-change="changeSelect(scope.row,'single' + index)"-->
<!--                        :disabled="batchStatus && scope.row['isEdit' + index]"-->
<!--              />驳回限制-->
<!--              <Tooltip>-->
<!--                <Icon type="ios-help-circle-outline" :size="16" />-->
<!--                <div slot="content" style="white-space: normal">-->
<!--                  <p>当前数据通过后如果被驳回无法再次向前驳回</p>-->
<!--                </div>-->
<!--              </Tooltip>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div style="height: 20px" >-->
<!--             <p style="cursor: pointer;" v-if="index > 0" @click="changeLock(scope.row,'isLock' + index,scope.row['isEdit' + index])">{{scope.row['isLock' + index] =='0' ? '未锁定' : '锁定'}}</p>-->
<!--          </div>-->

<!--        </template>-->
<!--      </el-table-column >-->
<!--      <el-table-column-->
<!--          prop="receive_expire"-->
<!--          label="单次任务时间">-->
<!--        <template slot-scope="scope">-->
<!--          <el-select v-model="scope.row.receive_expire"-->
<!--                     popper-class="select-popper"-->
<!--                     :disabled="batchStatus && scope.row['receive_expire_isEdit']"-->
<!--                     @change="changeSelect(scope.row,'receive_expire')"-->
<!--          >-->
<!--            <el-option-->
<!--                v-for="item in prescriptionList"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--          <div style="height: 40px;display: flex;align-items: center">-->
<!--          </div>-->
<!--          <div style="height: 20px">-->

<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="receive_count"-->
<!--          label="单次任务个数">-->
<!--        <template slot-scope="scope">-->
<!--          <el-select v-model="scope.row.receive_count"-->
<!--             popper-class="select-popper"-->
<!--             :disabled="batchStatus && scope.row['receive_count_isEdit']"-->
<!--                     @change="changeSelect(scope.row,'receive_count')"-->
<!--          >-->
<!--            <el-option-->
<!--                v-for="item in questionsNumList"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--          <div style="height: 40px;display: flex;align-items: center">-->
<!--          </div>-->
<!--          <div style="height: 20px">-->

<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--    </el-table>-->
  </div>

  <div class="bot">
    <Button class="bot-btn" @click="pre">{{ $t('project_create_pre') }}</Button>
    <Button type="primary"  @click="setUser" :loading="publishLoading">{{projectDataObj.release_status >= 5 ? '保存' : $t('project_create_personnel_publish') }}</Button>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "projectPersonnel",
  data(){
    return {
      columns: [],
      dataList: [],
      batchModal:false,
      userList:[],
      procedureList:[],
      selectDataList:[],  //选中的数据
      selectProcedureList:[],
      modalLoading:false,
      isBatch:false,  //是否为批量设置
      curData:{}, //单条设置数据
      publishStatus:false,  //是否可发布
      publishLoading:false, //发布按钮loading
      projectDataObj:{},
      prescription:'',
      prescriptionList:[],  //任务时效列表
      questionsNum:'10',
      questionsNumList:[],
      recommendUsers:[],
      batchStatus:false,
      syncSettingsList:[],
      allSelect:false,
    }
  },
  props:{
    projectId:{
      type:String,
      default:0
    },
    projectData:{
      type:Object,
      default:()=>{}
    },
  },
  watch:{
    projectData:{
      // 数据发生变化就会调用这个函数
      handler(newVal, oldVal) {
        this.init();
      },
      immediate: true
    }
  },
  created(){
    // this.getUserList();
    this.getFormData();
  },
  methods: {
    getFormData(){
      this.api.production.projectForm().then((res) => {
        this.prescriptionList = [];
        for(let name in res.taskExpireTypes){
          this.prescriptionList.push({
            label:res.taskExpireTypes[name],
            value:name
          })
        }
        this.prescription = this.prescriptionList[0].value;
        for(let name in res.taskReceiveCountTypes){
          this.questionsNumList.push({
            label:res.taskReceiveCountTypes[name],
            value:name
          })
        }
        // this.recommendUsers = res.recommendUsers
      });
    },
    init(){

      this.projectDataObj = JSON.parse(JSON.stringify(this.projectData));
      this.procedureList = this.projectDataObj.steps;

      // console.log(this.procedureList,'this.procedureListthis.procedureList')
      // this.columns = this.formateColumn(this.procedureList);

      this.dataList = this.formateData(this.projectDataObj.batches, this.procedureList);
      this.publishStatus = this.projectDataObj.batch_setting_status == '1';


      this.syncSettingsList = this.procedureList.map((item,index)=>{
        return{
          name:item.name,
          keyName:'isEdit' + index,
          id:item.id,
          status:false,
        }
      });
      this.syncSettingsList.push({
        name:'单次任务时间',
        keyName:'receive_expire_isEdit',
        id:'receive_expire',
        status:false,
      })
      this.syncSettingsList.push({
        name:'单次任务个数',
        keyName:'receive_count_isEdit',
        id:'receive_count',
        status:false,
      })

    },
    getDetail() {
      let data = {
        project_id: this.projectId,
      }
      this.api.production.projectDetail(data).then((res) => {
        this.procedureList = res.project.steps;
        this.selectProcedureList = this.procedureList.map((item) => {
          return {
            name: item.name,
            stepId: item.id,
            ids: [],
            status: true,
            userList:[],
            loading:false,
          };
        });
        this.columns = this.formateColumn(this.procedureList);
        this.dataList = this.formateData(res.project.batches, this.procedureList);
        this.publishStatus = res.project.batch_setting_status == '1';
      })
    },

    formateData(data, steps) {
      data.forEach((item) => {
        item.name = util.decrypt(item.name)

        if (item.tasks.length){

          steps.forEach((sItem, index) => {
            let taskUserList = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].taskUsers;
            let indexName = taskUserList.map((item) => {
              return item.user_id;
            })
            this.$set(item,'index' + index,indexName);

            let userList = taskUserList.map((item) => {
              return {
                nickname:item.user.nickname,
                id:item.user_id
              };
            })
            this.$set(item,'userList' + index,userList);
            this.$set(item,'isEdit' + index,false);



            let single = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].refuse_limit;

            this.$set(item,'single' + index,single == '0'? false:true);

            let isLock = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].is_lock;

            this.$set(item,'isLock' + index,isLock);

          })
          // this.$set(item,'index' + steps.length,item.tasks[0].receive_count);
          // this.$set(item,'index' + steps.length + 1,item.tasks[0].receive_expire);
          this.$set(item,'receive_count',item.tasks[0].receive_count);
          this.$set(item,'receive_expire',item.tasks[0].receive_expire);


          this.$set(item,'receive_count_isEdit',false);
          this.$set(item,'receive_expire_isEdit',false);
          this.$set(item,'status',false);
        }
      });
      return data;
    },
    getUserList(data,query,name) {
      let params = {
        pageSize: 10,
        page: 1,
        keyword: query,
      };
      data.loading = true;
      this.api.production.userList(params).then((res) => {
        data.loading = false;
        data[name] = res.list;
      }).catch((err)=>{
        data.loading = false;
      })
    },
    selectData(item) { //选择数据
      if(item.status){
        this.selectDataList.push(item);
      }else{
        let index = this.selectDataList.findIndex((sItem)=>{
          return sItem.id == item.id
        })
        this.$delete(this.selectDataList,index)
      }
      if(this.selectDataList.length == this.dataList.length){
        this.allSelect = true;
      }else{
        this.allSelect = false;
      }
      this.dataIsEdit();
    },
    selectDataAll(status){
      if(status){
        this.selectDataList = JSON.parse(JSON.stringify(this.dataList));
        this.dataList.forEach((item)=>{
          item.status = true;
        })
      }else{
        this.selectDataList = [];
        this.dataList.forEach((item)=>{
          item.status = false;
        })
      }
      this.dataIsEdit();
    },
    setUser() {  //设置确定
      let data = [];

      this.dataList.forEach((item) => {
        // if(len){
          this.procedureList.forEach((sItem,index) => {
            if (sItem.status) {
              let single = item['single' + index] ? 1 : 0;
              data.push(item.id + '|' + sItem.id + '|' +item['receive_count'] + '|' + item['receive_expire'] + '||||' + item['index' + index].join(',') + '|' + single + '|' + item['isLock' + index]);
            }
          })
      });

      let params = {
        op: 'task',
        project_id: this.projectId,
        tasks: JSON.stringify(data)
      };
      // this.modalLoading = true;
      this.api.production.projectPublish(params).then((res) => {
        this.publish();
      }).catch((err) => {
        this.modalLoading = false;
      })

    },
    pre(){
      this.$emit('pre')
    },
    publish(){  //发布项目

      let data = {
        op:'release',
        project_id:this.projectId
      };
      this.publishLoading = true;
      this.api.production.projectPublish(data).then((res)=>{
        this.publishLoading = false;
        this.$router.push({
          path:'/production/project'
        })
      }).catch((err)=>{
        this.publishLoading = false;
      })
    },
    remoteMethod(query,data,name){ //模糊搜索
      if (query !== '') {
        this.getUserList(data,query,name);
      } else {
        data[name] = [];
      }
    },
    batchSet(){
      this.batchStatus = !this.batchStatus;

      if(!this.batchStatus){
        this.selectDataList = [];
        this.dataList.forEach((item)=>{
          item.status = false;
        })
        this.syncSettingsList.forEach((item)=>{
          item.status = false;
        })
        this.allSelect = false;
      };

      this.dataList.forEach((item)=>{
        for(let name in item){
          if(name.indexOf('isEdit') != -1){
            item[name] = this.batchStatus;
          }
        }
      })
      // this.$nextTick(()=>{
      //   this.$refs.multipleTable.doLayout()
      // })


    },
    changeProceDure(){
      this.dataIsEdit();
    },
    dataIsEdit(){
      this.syncSettingsList.forEach((item)=>{
        this.dataList.filter((sItem)=>{
          let len = this.selectDataList.filter((ssItem)=>{
            return ssItem.id == sItem.id;
          }).length;

          if(item.status){
            if(len){
              sItem[item.keyName] = false;
            }else{
              sItem[item.keyName] = true;
            }
          }else{
            sItem[item.keyName] = true;
          }
        })

      })
    },
    changeSelect(row,name,list){

      let data = row[name];
      let userList = [];
      if(list){
        userList = row[list];
      }

      this.dataList.filter((item)=>{
        let len = this.selectDataList.filter((sItem)=>{
          return item.id == sItem.id;
        }).length;

        if(len){
          if(list){
            item[list] = JSON.parse(JSON.stringify(userList));
          }
          item[name] = data;
        }
      })

      console.log(this.dataList,'this.selectDataList')
    },
    changeLock(row,name,isEdit){
      let data = row[name] == '0' ? '1' :'0';
      if(this.batchStatus){
        if(!isEdit){
          this.selectDataList.forEach((item)=>{
            item[name] = data;
          })
        }else{
          return;
        }
      }else{
        row[name] = data;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.project-person{
  .top{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .sync-settings{
      margin-left: 20px;
      .sync-settings-name{
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
  .cont{
    height: 430px;
    overflow-y: auto;
    .cont-table{
      width: 100%;
      >tr{
        border-bottom: 1px solid #e8eaec;
      }
    }
  }
  .bot{
    margin-top: 20px;
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bot-btn{
      margin-right: 20px;
    }
  }
  .recommend-user{
    width: 160px;
    word-break: break-all;
    margin-left: 10px;
    cursor: pointer;
    color:#2d8cf0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >span{
      margin-right: 10px;
    }
  }
}
.batch-modal{
  display: flex;
  justify-content: flex-start;

  .batch-modal-label{
    width: 100px;
    text-align: right;
  }
  .batch-modal-val{
    margin-bottom: 10px;
  }

  .batch-modal-check-group{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .batch-modal-check-item{
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
.batch-modal-item{
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  >span{
    display: inline-block;
    width: 100px;
    text-align: right;
  }

}
.date-center{
  align-items: center;
}
.val-w{
  width: 250px;
}
.val-w1{
  width: 292px;
}
.select-popper{
  z-index: 9999!important;
  top:auto !important;
  left: auto !important;
}
</style>
