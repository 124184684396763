<template>
<div class="project-info">
  <Form  :label-width="100" ref="formValidate" :model="formItem" :rules="ruleValidate">
    <FormItem :label="$t('project_create_info_name')" prop="name">
      <Input v-model="formItem.name" :placeholder="$t('project_create_info_name_tip')" class="w300"></Input>
    </FormItem>
    <FormItem :label="$t('project_create_info_date')" prop="date">
      <DatePicker @on-change="changeDate" v-model="formItem.date" type="daterange" :options="dateOptions" format="yyyy-MM-dd "  placement="bottom-end" :placeholder="$t('project_create_info_date_tip')" class="w300"></DatePicker>
    </FormItem>
    <FormItem :label="$t('project_create_info_person')" prop="user">
      <Select v-model="formItem.user"
              ref="select"
              remote
              :remote-method="remoteMethod2"
              filterable class="w300" :placeholder="$t('project_create_info_person_tip')"
      >
        <Option v-for="item in userList" :value="item.id" :key="item.id">{{ item.nickname }}</Option>
      </Select>
    </FormItem>
    <FormItem :label="$t('project_create_info_careful')">
      <div class="notice">
        <div>
          <Richtxt :dataObj="{val:formItem.description}" @saveData="(data)=>saveRichData(data)"></Richtxt>
<!--          <Input v-model="formItem.description" type="textarea" class="seth"  :placeholder="$t('project_create_info_careful_tip')" />-->
        </div>
        <!-- <div class="rule-template">
          <p class="tit">{{ $t('project_create_info_rule') }}</p>
          <div class="item">1.<span>{{ $t('project_create_info_rule_one') }}</span></div>
          <div class="item">2.<span>{{ $t('project_create_info_rule_two') }}2</span></div>
          <div class="item">3.<span>{{ $t('project_create_info_rule_two') }}3</span></div>
          <div class="item">4.<span>{{ $t('project_create_info_rule_two') }}4</span></div>
          <div class="item">5.<span>{{ $t('project_create_info_rule_two') }}5</span></div>
        </div> -->
      </div>
    </FormItem>
    <FormItem :label="$t('project_create_info_file')">
      <div class="upload-file">
        <Upload :action="constant.URL + '/uploadfile/upload'"
                :data="{
                  project_id:projectId,
                  type:2
                }"
                :headers="{
                  'access-token':Cookies.get(constant.tokenName)
                }" :on-success="fileSuccess"
                :show-upload-list="false"
                :before-upload="beforeUpload"
                accept=".doc,.docx,.xls,.xlsx,.pdf"
                multiple

        >
          <Button icon="ios-cloud-upload-outline">{{$t('project_create_info_file_btn')}}</Button>
        </Upload>
        <div class="upload-file-message"><span>{{ $t('project_create_info_file_message') }}</span><Icon type="ios-help-circle-outline"  /></div>
      </div>
      <div class="file-list">
        <div class="item" v-for="(item,index) in fileList" :key="index" @click="checkFile(item)">
          <div class="close" @click="deleFile(item)" >X</div>
          <div class="img">
            <Icon type="ios-document-outline" size="65" class="img-icon" />
            <p class="hz">{{ item.hz }}</p>
          </div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
    </FormItem>
  </Form>
  <div class="bot">
    <Button class="bot-btn" :loading="saveLoading" @click="save">{{ $t('project_create_save') }}</Button>
    <Button type="primary" :loading="loading" @click="next('formValidate')">{{ $t('project_create_info_next') }}</Button>
  </div>
</div>
</template>

<script>
import Cookies from "js-cookie";
import util from '@/utils/tools.js';
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "project-info",
  data(){
    const validatorDate = function (rule, value, callback){
      if(Array.isArray(value)){ //格式为：daterange、datetimerange检测
        value.map(function(item){
          if(item === ""){
            return callback("日期不能为空")
          }
        })
      }
      return callback()
    };
    return {
      Cookies:Cookies,
      userList: [],
      user:'',
      formItem:{
        name:'',
        date:'',
        user:'',
        description:'',
        uploadfile:'',
        userName:'',
      },
      value8:'',
      ruleValidate: {
        name: [
          { required: true, message: this.$t('project_create_info_name_tip'), trigger: 'blur' }
        ],
        date: [
          { required: true, validator:validatorDate, message: this.$t('project_create_info_date_tip'), trigger: 'blur' }
        ],
        user: [
          { required: true, message: this.$t('project_create_info_person_tip'), trigger: 'change' }
        ],
      },
      fileList:[],
      loading:false,
      saveLoading:false,
      userLoading:false,
      dateOptions:{
        // disabledDate (date) {
        //   let oDate;
        //   if(this.projectData.start_time * 1000 < Date.now()){
        //     oDate = this.projectData.start_time * 1000;
        //   }else{
        //     oDate = Date.now();
        //   }
        //
        //   return date && date.valueOf() < oDate - 86400000;
        // }
      }
    }
  },
  props:{
    projectId:{
      type:String,
      default:0
    },
    projectData:{
      type:Object,
      default:{}
    }
  },
  watch:{
    projectData:{
      // 数据发生变化就会调用这个函数
      handler(newVal, oldVal) {
        this.init();
      },
      immediate: true
    }
  },
  components:{
    Richtxt
  },
  created(){
    // this.getUserList();
  },
  methods:{
    saveRichData(data){
      this.formItem.description = data;
      this.projectData.attr.description = data;
    },
    init(){ //初始化数据
      let _this = this;
      if(this.projectData.name){
        this.formItem.name = this.projectData.name;
      }
      if(this.projectData.start_time && this.projectData.end_time){
        this.formItem.date = [util.timeFormatter(
            new Date(+this.projectData.start_time * 1000),
            'yyyy-MM-dd hh:mm:ss'
        ),util.timeFormatter(
            new Date(+this.projectData.end_time * 1000),
            'yyyy-MM-dd hh:mm:ss'
        )];

        this.dateOptions = {
          disabledDate (date) {
            let oDate;

            if(_this.projectData.start_time * 1000 < Date.now() - 86400000){
              oDate = _this.projectData.start_time * 1000;
            }else{
              oDate = Date.now() - 86400000;
            }
            let endDate;
            endDate = Date.now() + 86400000 * 30 * 6;
            return date && (date.valueOf() < oDate || date.valueOf() > endDate);
          }
        }
      }
      if(this.projectData.leaderUser && this.projectData.leaderUser.id){
        this.formItem.user = this.projectData.leaderUser.id;
        this.formItem.userName = this.projectData.leaderUser.nickname;
        this.$nextTick(() => {
          // 初始值
          this.$refs.select.$data.query = this.formItem.userName
          console.log(this.$refs.select.$data.query,'this.$refs.select.$data.query')
        })
      }
      if(this.projectData.attr && this.projectData.attr.description){
        this.formItem.description = this.projectData.attr.description;
      }
      if(this.projectData.attr && this.projectData.attr.attachments){
        let attachmentsArr = JSON.parse(this.projectData.attr.attachments);
        this.fileList = attachmentsArr.map((item)=>{
          let obj = {};
          let url = item.split('/');
          obj.name = url[url.length-1];
          obj.url = item;
          let hzArr = item.split('.');
          obj.hz = hzArr[hzArr.length - 1];

          return obj;
        });
      }
    },
    getUserList(data){
      let params = {
        pageSize:20,
        page:1,
        keyword:data || '',
      };

      this.userLoading = true;
      this.api.production.userList(params).then((res)=>{
        this.userLoading = false;
        this.$nextTick(()=>{
          this.userList = res.list;
        })
      }).catch((err)=>{
        this.userLoading = false;
      })
    },
    beforeUpload(){
      if(this.fileList.length >= 5){
        this.$Message.warning(this.$t('project_create_info_file_tip_num'));
        return false;
      }
    },
    changeDate(e){
      this.formItem.date = e;
    },
    save(){
      this.next('formValidate','save');
    },
    next(name,type){
      console.log(this.formItem.date,'this.formItem.datethis.formItem.date')
      this.$refs[name].validate((valid) => {
        if (valid) {
          let urls = this.fileList.map((item)=>{
            return item.url;
          });

          let data = {
            op:'updateinfo',
            name:this.formItem.name,
            description:this.formItem.description,
            project_id:this.projectId,
            leader_user_id:this.formItem.user,
            start_time:new Date(this.formItem.date[0]).getTime()/1000, //日期的 00:00:00 秒
            end_time:new Date(this.formItem.date[1]).getTime()/1000 + 86400 - 1, //日期的23:59:59 秒
          };
          if(urls.length){
            data.attachments = JSON.stringify(urls);
          }
          if(type == 'save'){
            this.saveLoading = true;
          }else{
            this.loading = true;
          }
          this.api.production.projectPublish(data).then((res)=>{
            if(type== 'save'){
              this.saveLoading = false;
              this.$emit('save');
            }else{
              this.loading = false;
              this.$emit('next');
            }

          }).catch((err)=>{
            if(type== 'save'){
              this.saveLoading = false;
            }else{
              this.loading = false;
            }
          })
        }
      })

    },
    fileSuccess(response,file, fileList){  //上传文件成功
      console.log(file,'dddd')
      let arr = file.name.split('.');
      let data = {
        name:file.name,
        hz:arr[arr.length - 1],
        url: response.data.info.upload_path
      };
      this.fileList.push(data)
    },
    deleFile(data){ //删除文件
      console.log(data,'ddd')
     let num =  this.fileList.findIndex((item)=>{
        return item.url == data.url;
      });
      this.fileList.splice(num,1);
    },
    remoteMethod2 (query) {
      console.log(query,'query')
      if (query !== '' && query != this.formItem.user) {
        this.getUserList(query);
      } else {
        this.userList = [];
      }
    },
    // checkFile(data){
    //   window.open(data.url.splice(0,1));
    // }
  }
}
</script>

<style scoped lang="scss">
.w300{
  width: 300px;
}
.project-info{
  padding-top: 20px;
  .notice{
    display: flex;
    justify-content: flex-start;
    //height: 260px;

    >div:nth-child(1){
      flex: 6;
      border:1px solid #dcdee2;
    }
    >div:nth-child(2){
      margin-left: 20px;
      flex: 1;
      border:1px solid #dcdee2;
      border-radius: 4px;
    }
    .rule-template{
      padding-top: 10px;
      text-align: center;

      .tit{
        margin-bottom: 10px;
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #dcdee2;
      }
      .item{
        height: 40px;
        >span{
          margin-left: 10px;
          display: inline-block;
          width: 100px;
          border: 1px solid #dcdee2;
          border-radius: 4px;
        }
      }
    }
  }
  .upload-file{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .upload-file-message{
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        padding-right: 5px;
      }
    }
  }
  .file-list{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;

    .item{
      margin-right: 20px;
      width: 80px;
      height: 100px;
      border:1px solid #dcdee2;
      border-radius: 4px;
      text-align: center;
      position: relative;

      .close{
        position: absolute;
        right: 5px;
        top:5px;
        text-align: right;
        //width: 24px;
        line-height: 12px;
        cursor: pointer;
        overflow: hidden;
      }

      .img{
        margin-top: 10px;
        margin-left: 0;
        position: relative;
        .img-icon{
          position: absolute;
          top:0;
          left: 5px;
        }

        .hz{
          position: absolute;
          top:28px;
          left: 30px;
          height: 20px;
          line-height: 20px;
          border:1px solid #000000;
          background-color: #FFFFFF;
          border-radius: 4px;
          padding: 0 3px;
        }
      }
      .name{
        position: absolute;
        bottom:5px;
        text-align: center;
        margin: 0;
        padding: 0;
        height: 25px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .bot{
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bot-btn{
      margin-right: 20px;
    }
  }
}
</style>
<style lang="scss">
.project-info{
  .notice{
    .ivu-input{
      height: 260px !important;
    }
  }
}
</style>
