<template>
<div class="project-procedure">
  <div class="cont">
    <div class="left">
      <div class="item" v-for="(item,index) in list" :key="index">
        <p class="tit">{{item.name}}{{$t('project_create_procedure_procedure')}}</p>
        <div class="val">
          <div :class="{active : sItem == item.curVal }" @click="changeNum(item,sItem)" v-for="(sItem,sIndex) in item.value" :key="sIndex + 'sub'">{{sItem}}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div v-for="(item,index) in list" :key="index + 'check'">
        <div class="item" v-for="(sItem,sIndex) in item.curVal" :key="sIndex + 'subcheck'">
          <p class="tit" :style="{color:item.color,border:`1px solid ${item.color}`}">{{ item.name }}{{sIndex + 1}}</p>
          <div class="fgx" v-if="(index + 1) < list.length">
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bot" >
    <Button class="bot-btn" @click="pre">{{$t('project_create_pre')}}</Button>
    <Button :loading="saveLoading" class="bot-btn" @click="save">{{$t('project_create_save')}}</Button>
    <Button :loading="nextLoading" type="primary" @click="next">{{$t('project_create_info_next')}}</Button>
  </div>

</div>
</template>

<script>
export default {
  name: "projectProcedure",
  data(){
    return {
      list:[],
      saveLoading:false,
      nextLoading:false
    }
  },
  props:{
    projectId:{
      type:String,
      default:0
    },
    projectData:{
      type:Object,
      default:{}
    },
    type:{
      type:String,
      default:''
    },
  },
  watch:{
    projectData:{
      // 数据发生变化就会调用这个函数
      handler(newVal, oldVal) {
        this.$nextTick(()=>{
          this.init();
        });
      },
      immediate: true
    }
  },
  methods:{
    init(){
      this.list = [
        {
          name:this.$t('project_create_procedure_produce'),
          value:1,
          curVal:1,
          color:'#19be6b',
          type:0,
          val:0
        },
        {
          name:this.$t('project_create_procedure_audit'),
          value:5,
          curVal:2,
          color:'#ff9900',
          type:1,
          val:0
        },
        {
          name:this.$t('project_create_procedure_check'),
          value:1,
          curVal:1,
          color:'#ed4014',
          type:2,
          val:0
        },
        {
          name:this.$t('project_create_procedure_acceptance'),
          value:1,
          curVal:1,
          color:'#2d8cf0',
          type:3,
          val:0
        }
      ];
      this.projectData.steps.forEach((item)=>{
        this.list.forEach((sItem)=>{
          if(sItem.type == item.type){
            sItem.val += 1;
            sItem.curVal = sItem.val;
          }
        })
      });
      console.log(this.list,'this.list')
    },
    changeNum(data,num){
      // if(this.type=='change') return;
      data.curVal = num;
    },
    pre(){
      this.$emit('pre')
    },
    save(){
      this.next('save')
    },
    next(type){
      let data = {
        op:'step',
        project_id:this.projectId,
        produce_count:this.list[0].curVal,
        audit_count:this.list[1].curVal,
        check_count:this.list[2].curVal,
        acceptance_count:this.list[3].curVal,
      };
      if(type=='save'){
        this.saveLoading = true;
      }else{
        this.nextLoading = true;
      }
      this.api.production.projectPublish(data).then((res)=>{
        if(type=='save'){
          this.saveLoading = false;
          this.$emit('save')
        }else{
          this.nextLoading = false;
          this.$emit('next');
          this.$EventBus.$emit('getNum', this.list);
        }
      }).catch((err)=>{
        if(type=='save'){
          this.saveLoading = false;
        }else{
          this.nextLoading = false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.project-procedure{
  .cont{
    display: flex;
    justify-content: space-between;

    .left{
      .item{
        margin-top: 30px;

        .tit{
          font-size: 14px;
        }

        .val{
          display: flex;
          justify-content: flex-start;
          text-align: center;


          >div{
            margin-top: 20px;
            margin-right: 30px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            border: 1px solid #dcdee2;
            font-size: 16px;
            cursor: pointer;
          }
          >div.active{
            border: 1px solid #2d8cf0;
            background-color: #2d8cf0;
            color:#FFFFFF;
          }
          >div.active.gray{
            border: 1px solid #c5c8ce;
            background-color: #c5c8ce;
          }
        }
      }
    }
    .right{
      margin-bottom: 20px;
      padding: 30px 0;
      width:400px;
      height: 600px;
      overflow-y: auto;
      background-color: #f8f8f9;

      .item{
        text-align:center;

        .tit{
          margin: 0 auto;
          width: 120px;
          text-align: center;
          height: 60px;
          line-height: 60px;
          border:1px solid #19be6b;
          font-size: 16px;
          font-weight: bold;
          border-radius: 10px;
        }
        .fgx{
          margin: 10px 0;
          >p:nth-child(1){
            margin: 0 auto;
            height: 40px;
            width: 2px;
            color:#808695;
            border-left: 2px solid #808695;
          }
          >p:nth-child(2){
            margin: 0 auto;
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color:#808695 transparent transparent transparent;
          }
        }
      }
    }
  }

  .bot{
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bot-btn{
      margin-right: 20px;
    }
  }

}
</style>
