import { render, staticRenderFns } from "./projectPersonnel.vue?vue&type=template&id=286550a7&scoped=true&"
import script from "./projectPersonnel.vue?vue&type=script&lang=js&"
export * from "./projectPersonnel.vue?vue&type=script&lang=js&"
import style0 from "./projectPersonnel.vue?vue&type=style&index=0&id=286550a7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286550a7",
  null
  
)

export default component.exports