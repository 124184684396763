<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="project-create">
      <div class="project-create-top">
        <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{item.id}}.{{ item.name }}</div>
      </div>
      <div class="cont">
        <p class="cont-tit" v-if="curTab.id != 2">{{curTab.name}}</p>
        <div class="cont-com">
          <!--        <keep-alive>-->
          <component :is="componentObj[curTab.id]" :projectData="projectDetail" :type="type" :projectId="projectId" @save="save" @next="next" @pre="pre"></component>
          <!--        </keep-alive>-->
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import ProjectProcedure from './components/create/projectProcedure'
import ProjectData from './components/create/projectData'
import ProjectBatch from './components/create/projectBatch'
import ProjectPersonnel from './components/create/projectPersonnel'
import ProjectInfo from './components/create/projectInfo'

export default {
  name:'project-create',
  data(){
    return {
      componentObj:{
        1:'ProjectInfo',
        2:'ProjectData',
        3:'ProjectBatch',
        4:'ProjectProcedure',
        5:'ProjectPersonnel'
      },
      tabList:[
        {
          name:this.$t('project_create_tab_info'),
          id:1,
          releaseStatus:1,
        },
        {
          name:this.$t('project_create_tab_data'),
          id:2,
          releaseStatus:2,
        },
        {
          name:this.$t('project_create_tab_batch'),
          id:3,
          releaseStatus:3,
        },
        {
          name:this.$t('project_create_tab_procedure'),
          id:4,
          releaseStatus:4,
        },
        {
          name:this.$t('project_create_tab_personnel'),
          id:5,
          releaseStatus:5,
        }
      ],
      curTab:{
        // name:this.$t('project_create_tab_info'),
        // id:1,
      },
      projectId:'',
      releaseStatus:0,
      projectDetail:{}, //项目详情
      type:'',  //change 修改 continue 继续发布
    }
  },
  components:{
    ProjectInfo,
    ProjectProcedure,
    ProjectData,
    ProjectBatch,
    ProjectPersonnel
  },
  created(){
    this.projectId = this.$route.query.id;
    if(this.$route.query.type){
      this.type = this.$route.query.type;
    }
    this.getDetail();

  },
  methods:{
    getDetail() {
      let data = {
        project_id: this.projectId,
      }
      this.api.production.projectDetail(data).then((res) => {
        this.projectDetail = res.project;

        this.releaseStatus = Number(this.projectDetail.release_status) == 5 ? Number(this.projectDetail.release_status) : Number(this.projectDetail.release_status)  + 1;
        if(this.releaseStatus <= 5){
          this.curTab = this.tabList.filter((item)=>{
            return item.releaseStatus == this.releaseStatus;
          })[0];
        }else{
          this.curTab = {
            name:this.$t('project_create_tab_info'),
            id:1,
          };
        }
      });
    },
    save(){ //保存
      this.$router.push({
        name:'project'
      })
    },
    next(){ //下一步
      // let index = this.tabList.findIndex((item)=>{
      //   return item.id == this.curTab.id;
      // });
      // this.curTab = this.tabList[index + 1];

      // if(this.projectDetail.status == '0'){ //只有发布中的项目需要更新数据
      //   this.getDetail();
      // }
      this.getDetail();

    },
    pre(){  //上一步
      let index = this.tabList.findIndex((item)=>{
        return item.id == this.curTab.id;
      });
      this.curTab = this.tabList[index - 1];
    },
    changeTab(data){
      if(this.type=="change"){  //如果是编辑的话可以切换
        this.curTab = data;
      }else{
        if(data.id > this.curTab.id) return;
        this.curTab = data;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.project-create{
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .project-create-top{
    height: 48px;
    line-height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:1px solid #dcdee2;
    border-radius: 6px;
    overflow: hidden;

    >div{
      flex: 1;
      text-align: center;
      border-right: 1px solid #dcdee2;
      font-size: 14px;
      cursor:pointer;
    }
    >div:last-child{
      border-right: none;
    }
    >div.active{
      background: #2d8cf0;
      color:#ffffff;
    }
  }
  .cont{
    .cont-tit{
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #dcdee2;
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
