<template>
<div class="project-data">
  <div class="cont-top">
    <div class="tab">
      <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
    </div>
  </div>
  <component :is="curTab.componentName" :projectId="projectId" :projectData="projectData" :type="type" @changeData="changeData"></component>
  <div class="bot" v-if="projectData.status == '0'">
    <Button class="bot-btn" @click="pre">{{ $t('project_create_pre') }}</Button>
    <Button class="bot-btn" @click="save">{{ $t('project_create_save') }}</Button>
    <Button type="primary" @click="next">{{ $t('project_create_info_next') }}</Button>
  </div>
</div>
</template>

<script>
import DataList from './dataList';
import MapList from './mapList';
import CategoryList from './categoryList';

export default {
  name: "projectData",
  data(){
    return{
      nextLoading:false,
      saveLoading:false,
      selectDataList:[],
      tabList:[],
      allTab:[
        {
          name:'数据文件',
          id:0,
          componentName:'DataList',
        },
        {
          name:'知识图谱',
          id:2,
          componentName:'MapList',
        },
        {
          name:'分类',
          id:1,
          componentName:'CategoryList',
        }
      ],
      curTab:{},
    }
  },
  props:{
    projectId:{
      type:String,
      default:0
    },
    type:{
      type:String,
      default:''
    },
    projectData:{
      type:Object,
      default:{}
    }
  },
  watch:{
    projectData:{
      handler(newV){
        let arr = newV.category.datasource_from.split(',');
        this.tabList = [];
        this.allTab.forEach((item)=>{
          let obj = arr.filter((sItem)=>{
            return sItem == item.id;
          });
          if(obj.length) this.tabList.push(item);
        })
        if(newV.datasource_type){
          this.curTab = this.tabList.filter((item)=>{
            return item.id == newV.datasource_type;
          })[0];
          if(!this.curTab){
            this.curTab = this.tabList[0];
          }
        }else{
          this.curTab = this.tabList[0];
        }
      },
      immediate:true
    }
  },
  components:{
    DataList,
    MapList,
    CategoryList
  },
  methods:{
    changeData(data){
      this.selectDataList = data;
    },
    pre(){
      this.$emit('pre')
    },
    save(){
      this.next('save')
    },
    next(type){
      if(!this.selectDataList.length){
        this.$Message.warning(this.$t('project_create_data_table_select_tip'));
        return;
      }
      if(this.selectDataList.length > 1){
        this.$Message.warning(this.$t('project_create_data_table_select_length_tip'));
        return;
      }
      let uploadfileId = this.selectDataList.map((item)=>{
        return item.id;
      }).join(',');
      let data = {
        op:'datasource',
        datasource_type: this.curTab.id,//数据源,0上传1分类2知识图谱
        datasource_id:uploadfileId,
        project_id:this.projectId
      }
      if(type=='save'){
        this.saveLoading = true;
      }else{
        this.nextLoading = true;
      }
      this.api.production.projectPublish(data).then((res)=>{
        if(type=='save'){
          this.saveLoading = false;
          this.$emit('save')
        }else{
          this.nextLoading = false;
          this.$emit('next');
        }
      }).catch((err)=>{
        if(type=='save'){
          this.saveLoading = false;
        }else{
          this.nextLoading = false;
        }
      })
    },
    changeTab(data){
      this.curTab = data;
      this.selectDataList = [];
    }
  }
}
</script>

<style scoped lang="scss">
.project-data{
  .cont-top{
    display: flex;

  }
  .tab{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border:1px solid #dcdee2;
    text-align: center;
    height: 46px;
    border-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    cursor: default;
    line-height: 46px;

    >div{
      width: 100px;
      border-right:1px solid #dcdee2;
      cursor: pointer;
      height: 100%;
    }
    >div:last-child{
      border-right:none;
    }
    >div.active{
      background-color: #2d8cf0;
      color:#FFFFFF;
    }
  }
  .bot{
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bot-btn{
      margin-right: 20px;
    }
  }
}
</style>
