<template>
  <div class="data-list">
    <div class="top">
      <div></div>
      <div>
        <Input :placeholder="$t('project_create_data_input')" search clearable style="width: 300px;" v-model="keyword" @on-search="search">
        </Input>
      </div>
    </div>
    <div>
      <Table ref="selection" border :columns="columns" :data="dataList" :loading="loading" @on-select="selectData" @on-select-cancel="selectData" @on-select-all="selectData" @on-select-all-cancel="selectData"></Table>
    </div>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change = "changePageSize" :current="page" show-total show-elevator show-sizer placement = "top" transfer />
    </div>
  </div>
</template>

<script>
import util from "@/utils/tools.js";
export default {
  name: "dataList",
  data(){
    return {
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: this.$t('project_create_data_table_id'),
          key: 'id',
        },
        {
          title: '知识图谱名称',
          key: 'name'
        },
        {
          title: '更新时间',
          render:(h, params) => {
            return h('div', {},util.timeFormatter(
                new Date(+params.row.updated_at * 1000),
                'yyyy-MM-dd hh:mm:ss'
            ));
          }
        },
        {
          title: this.$t('project_create_data_table_op'),
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  // type: '',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.show(params.row)
                  }
                }
              }, this.$t('project_create_data_table_check')),
              // h('Button', {
              //   props: {
              //     type: 'error',
              //     size: 'small'
              //   },
              //   on: {
              //     click: () => {
              //       this.remove(params.index)
              //     }
              //   }
              // }, this.$t('project_create_data_table_dele'))
            ]);
          }
        }
      ],
      dataList: [
      ],
      loading:false,
      page:1,
      pageSize: 10,
      keyword:'',
      total:0,
      selectDataList:[],
    }
  },
  props:{
    projectId:{
      type:String,
      default:0
    },
    projectData:{
      type:Object,
      default:{}
    },
    type:{
      type:String,
      default:''
    },
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      let data = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
      };
      this.loading = true;
      this.api.dataset.mapList(data).then((res)=>{
        this.loading = false;

        this.dataList = res.list;
        this.selectDataList = [];
        for(let i =0;i<this.dataList.length;i++){
          if(this.dataList[i].id == this.projectData.datasource_id){
            // this.dataList[i]._disabled = true;
            this.dataList[i]._checked = true;
            this.selectDataList.push(this.dataList[i]);
            this.$emit('changeData',this.selectDataList)
          }
          if(this.type == 'change'){
            this.dataList[i]._disabled = true;
          }
        }

        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    search(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    show(data){ //预览
      // window.open(this.constant.URL + data.upload_path);
    },
    selectData(data){
      console.log(data,'d')
      this.selectDataList = data;
      this.$emit('changeData',this.selectDataList)
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
    }
  }
}
</script>

<style scoped lang="scss">
.data-list{
  .top{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div:nth-child(1){
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-left: 20px;
        >span{
          color:#2d8cf0;
        }
      }
    }
  }
  .page{
    margin: 20px 0;
    text-align: right;
  }
}

</style>
