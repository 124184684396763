<template>
<div class="project-batch">
  <div v-if="projectDataObj.status == '0'">
    <div class="top">
      <div class="top-box" v-if="!loading">
        <div :class="curTab == item ? 'active' : ''" v-for="item in tabList" :key="item" @click="changeTab(item)">{{ item }}拆分</div>
      </div>
      <Spin fix v-else></Spin>
    </div>
    <div class="cont">
      <Table :loading="loading" :columns="columns" :data="dataList"></Table>
    </div>
  </div>
  <div v-else>
    <div class="top">
      <div class="top-box">
        <div>{{ curTab }}拆分</div>
      </div>
    </div>
    <div class="cont">
      <Table :columns="columns" :data="dataList"></Table>
    </div>
  </div>

  <div class="bot" v-if="projectDataObj.status == '0'">
    <Button class="bot-btn" @click="pre">{{ $t('project_create_pre') }}</Button>
    <Button :loading="saveLoading" class="bot-btn" @click="save">{{ $t('project_create_save') }}</Button>
    <Button :loading="nextLoading" type="primary" @click="next">{{ $t('project_create_info_next') }}</Button>
  </div>
</div>
</template>

<script>
import util from "@/utils/tools.js";
export default {
  name: "projectBatch",
  data(){
    return {
      tabList:[],
      curTab:'',
      columns: [],
      dataList: [],
      loading:false,
      allData:{},
      saveLoading:false,
      nextLoading:false,
      projectDataObj:{},
    }
  },
  props:{
    projectId:{
      type:String,
      default:0
    },
    projectData:{
      type:Object,
      default:()=>{}
    },
    type:{
      type:String,
      default:''
    },
  },
  watch:{
    projectData:{
      // 数据发生变化就会调用这个函数
      handler(newVal, oldVal) {

        this.init();
      },
      immediate: true
    }
  },
  created(){

  },
  methods:{
    init(){
      this.projectDataObj = JSON.parse(JSON.stringify(this.projectData));
      if(this.projectDataObj.attr && this.projectDataObj.attr.batch_config && this.projectDataObj.attr.batch_config.assign_column){
        this.curTab = this.projectDataObj.attr.batch_config.assign_column;
      }
      if(this.projectDataObj.status == '0'){
        this.getParse();
      }else{
        this.columns = this.formateColumn1(this.curTab);
        this.dataList = this.projectDataObj.batches;

        this.dataList.forEach((item)=>{
          console.log(item.name,'item.name')
          item.name = util.decrypt(item.name);
        })
      }
    },
    changeTab(data){
      this.curTab = data;
      this.dataList = this.formateData(this.allData[this.curTab]);
      this.columns = this.formateColumn(this.curTab);
    },
    getParse(){
      let data = {
        project_id:this.projectId,
        op:'datastructure',
      };
      this.loading = true;
      this.api.production.publish(data).then((res)=>{
        this.loading = false;
        this.tabList = Object.keys(res.columns);
        this.allData = res.columns;
        console.log(this.curTab)
        if(!this.curTab){
          this.curTab = this.tabList[0];
        }

        this.dataList = this.formateData(this.allData[this.curTab]);
        this.columns = this.formateColumn(this.curTab);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    formateColumn(data){
      let column = [
        {
          title: this.$t('project_create_batch_table_num'),
          type:'index'

        },
        {
          title: data,
          key: 'name'
        },
        {
          title: this.$t('project_create_batch_table_total'),
          key: 'value'
        }
      ];
      return column;
    },
    formateColumn1(data){
      let column = [
        {
          title: this.$t('project_create_batch_table_num'),
          type:'index'

        },
        {
          title: data,
          key: 'name'
        },
        {
          title: this.$t('project_create_batch_table_total'),
          key: 'amount'
        }
      ];
      return column;
    },
    formateData(data){
      let arr = [];
      for(let name in data){
        let obj = {};
        obj.name = name;
        obj.value = data[name];
        arr.push(obj);
      }
      return arr;
    },
    pre(){
      this.$emit('pre')
    },
    save(){
      this.next('save')
    },
    next(type){
      let data = {
        op:'batch',
        project_id:this.projectId,
        assign_column:this.curTab,
        assign_type:0,
      }
      if(type=='save'){
        this.saveLoading = true;
      }else{
        this.nextLoading = true;
      }
      this.api.production.projectPublish(data).then((res)=>{
        if(type=='save'){
          this.saveLoading = false;
          this.$emit('save')
        }else{
          this.nextLoading = false;
          this.$emit('next');
        }
      }).catch((err)=>{
        if(type=='save'){
          this.saveLoading = false;
        }else{
          this.nextLoading = false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.project-batch{
  .top{
    margin-top: 15px;
    display: inline-block;
    //min-width: 300px;
    //height: 38px;
    line-height: 38px;
    position: relative;
    text-align: center;


    .top-box{
      border:1px solid #2d8cf0;
      color:#2d8cf0;


      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;
      >div{
        //width: 100px;
        padding: 0 20px;
        border-right: 1px solid #2d8cf0;
        cursor: pointer;
      }
      >div:last-child{
        border-right: none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
  .cont{
    margin-top: 20px;
    height: 500px;
    overflow-y: auto;
  }
  .bot{
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bot-btn{
      margin-right: 20px;
    }
  }
}
</style>
