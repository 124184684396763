import { render, staticRenderFns } from "./projectData.vue?vue&type=template&id=24fb637e&scoped=true&"
import script from "./projectData.vue?vue&type=script&lang=js&"
export * from "./projectData.vue?vue&type=script&lang=js&"
import style0 from "./projectData.vue?vue&type=style&index=0&id=24fb637e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24fb637e",
  null
  
)

export default component.exports