<template>
  <div class="data-list">
    <div class="search">
      <div class="search-item">
        <div class="name">
          一级分类:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="search-item" v-if="secondCategoryList.length > 1">
        <div class="name">
          二级分类:
        </div>
        <div class="cont">
          <div class="values" >
            <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="top">
      <div></div>
      <div>
        <Input :placeholder="$t('project_create_data_input')" search clearable style="width: 300px;" v-model="keyword" @on-search="search">
        </Input>
      </div>
    </div>
    <div>
      <Table ref="selection" border :columns="columns" :data="dataList" :loading="loading" @on-select="selectData" @on-select-cancel="selectData" @on-select-all="selectData" @on-select-all-cancel="selectData"></Table>
    </div>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change = "changePageSize" :current="page" show-total show-elevator show-sizer placement = "top" transfer />
    </div>
  </div>
</template>

<script>
import util from "@/utils/tools.js";
export default {
  name: "dataList",
  data(){
    return {
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: this.$t('project_create_data_table_id'),
          key: 'id',
        },
        {
          title: '分类名称',
          key: 'name'
        },
        {
          title: '数据量',
          key: 'data_count'
        },
        {
          title: this.$t('project_create_data_table_op'),
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  // type: '',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.show(params.row)
                  }
                }
              }, this.$t('project_create_data_table_check')),
            ]);
          }
        }
      ],
      dataList: [
      ],
      loading:false,
      page:1,
      pageSize: 10,
      keyword:'',
      total:0,
      selectDataList:[],
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
    }
  },
  props:{
    projectId:{
      type:String,
      default:0
    },
    type:{
      type:String,
      default:''
    },
    projectData:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  created(){
    this.getList();
  },
  methods:{
    changeSearch(data,name){
      this[name] = data.id;
      this.getList();
    },
    getList(){
      let data = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        category_id:this.curCategoryId,
        second_category_id:this.cursecondCategoryId
      };
      this.loading = true;
      this.api.dataset.categoryList(data).then((res)=>{
        this.loading = false;

        this.dataList = res.list;

        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categorySecondList
        ];


        this.selectDataList = [];
        for(let i =0;i<this.dataList.length;i++){
          if(this.dataList[i].id == this.projectData.datasource_id){
            // this.dataList[i]._disabled = true;
            this.dataList[i]._checked = true;
            this.selectDataList.push(this.dataList[i]);
            this.$emit('changeData',this.selectDataList)
          }
          if(this.type == 'change'){
            this.dataList[i]._disabled = true;
          }
        }

        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    search(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    show(data){ //预览
      // window.open(this.constant.URL + data.upload_path);
    },
    selectData(data){
      this.selectDataList = data;
      this.$emit('changeData',this.selectDataList)
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
    }
  }
}
</script>

<style scoped lang="scss">
.data-list{
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .top{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div:nth-child(1){
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-left: 20px;
        >span{
          color:#2d8cf0;
        }
      }
    }
  }
  .page{
    margin: 20px 0;
    text-align: right;
  }
}

</style>
